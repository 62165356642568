import Vue from "vue";

/*TITLE*/
document.title = "Terrazas de Belisana | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Terrazas de Belisana";
Vue.prototype.$subtitle = "Calle Belisana 5 Madrid";

/*INTRO*/
Vue.prototype.$promoter = "Terrazas de Belisana";
Vue.prototype.$introSubtitle = "Calle Belisana 5 Madrid";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091504.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091504.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091504.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091504.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091504.jpeg",
  },
  {
    src: "1-livingroom--20221124091504.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091504.jpeg",
  },
  {
    src: "1-kitchen--20221124091504.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091504.jpeg",
  },
  {
    src: "1-bathroom1--20221124091504.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091504.jpeg",
  },
  {
    src: "1-bathroom2--20221124091504.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = false;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/belisana5/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = false;

/*CONTACT*/
Vue.prototype.$promoter_address = [
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20besilana%205%20madrid%2028043%20",
    text: "Calle Besilana, 5. Madrid 28043.",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:682168928",
    text: "682168928",
  },
  {
    icon: "mdi-email",
    link: "mailto:comercial@belisana.es",
    text: "comercial@belisana.es",
  },
];
